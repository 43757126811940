/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  add: {
    alignContent: 'right'
  },
  spaceLine: {
    whiteSpace: 'post-line'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const ARSFilter = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.auth.accountableAdmin && (
        <FormControl className={classes.formControl}>
          <InputLabel id="case-label">Filter by ARS</InputLabel>
          <Select
            labelId="case-label"
            id="case-select"
            value={props.arsUser}
            onChange={props.handleSetArsUser}>
            <MenuItem value={0} key={0}>
              {props.arsOnly ? 'Show My Cases' : 'Show All'}
            </MenuItem>
            {!props.arsOnly && (
              <MenuItem value="UNASSIGNED" key="UNASSIGNED">
                Need ARS Assignment
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="PROGRAMMANAGED" key="PROGRAMMANAGED">
                Program Managed
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="NOTPROGRAMMANAGED" key="NOTPROGRAMMANAGED">
                Not Program Managed
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="RECENTINTAKE" key="RECENTINTAKE">
                Intake Last 10 Days
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="RECENTREGISTER" key="RECENTREGISTER">
                Registered Last 10 Days
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="RECENTDX" key="RECENTDX">
                Discharged Last 10 Days
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="LABTESTING" key="LABTESTING">
                Lab Testing Members
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="INSTANTTEST" key="INSTANTTEST">
                Instant Testing Members
              </MenuItem>
            )}
            {!props.arsOnly && (
              <MenuItem value="INSURANCECARRIER" key="INSURANCECARRIER">
                Insurance Client
              </MenuItem>
            )}

            {props.filterByARS &&
              props.filterByARS.map((user) => {
                return (
                  <MenuItem value={user._id} key={user._id}>
                    {user.lastName}, {user.firstName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default ARSFilter;
