import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useHttpClient } from './http-hook';

interface ConfirmEmailHook {
  confirmEmail: string | undefined;
  clearConfirmEmail: () => void;
}

export const useConfirmEmail = (): ConfirmEmailHook => {
  const { sendRequest } = useHttpClient();
  const [confirmEmail, setConfirmEmail] = useState<string | undefined>();
  const [emailConfirmed, setEmailConfirmed] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const key = searchParams.get('key');

  useEffect(() => {
    if (key && !emailConfirmed) {
      setConfirmEmail('Thank you for confirming your email');
      const verifyEmail = async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/users/confirm?key=${key}`
          );
          if (responseData.valid) {
            setEmailConfirmed(true);
            // Remove the 'key' query parameter from the URL
            navigate('', { replace: true });
          } else {
            setConfirmEmail('Error Confirming Email. Please Try Again Later');
          }
        } catch (error) {
          setConfirmEmail('Error Confirming Email. Please Try Again Later');
        }
      };

      verifyEmail();
    }
  }, [key, emailConfirmed, sendRequest, navigate]);

  const clearConfirmEmail = () => {
    setConfirmEmail(undefined);
  };

  return { confirmEmail, clearConfirmEmail };
};
