import React, { useContext, useEffect, useState } from "react";
import ErrorModal from "../../../common/UI/ErrorModal";
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Card,
  Grid,
  Button,
  TableBody,
  CardContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AuthContext } from "../../../common/context/auth-context";
import LoadingSpinner from "../../../common/UI/LoadingSpinner";
import { useHttpClient } from "../../../common/hooks/http-hook";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AddTemplate from "./add-template";
import DeleteIcon from "@material-ui/icons/Delete"; 
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3), 
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  add: {
    alignContent: "right",
  },
}));

const Templates = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [templates, setTemplates] = useState([]); 
  const [editTemplate, setEditTemplate] = useState(null); //for payer edit button
  useEffect(() => {
    //get users for tenant
    const getTemplates = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/accountable/gettemplates",
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        if (responseData && !responseData.message) setTemplates(responseData);
      } catch (err) {}
    };
    getTemplates();
  }, [sendRequest, showModal]);

  const getTemplates = async (event) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/accountable/gettemplates",
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      if (responseData && !responseData.message) setTemplates(responseData.filter(template=>template.templateName.toLowerCase().includes(event.toLowerCase())));
    } catch (err) {}
  };

  const addTemplate = (event) => {
    event.preventDefault();
    setEditTemplate(null); 
    setShowModal(true);
  };

  const showEditModal = (event) => {
    setEditTemplate(event.currentTarget.value); 
    setShowModal(true); 
  }

  const deleteTemplate = async (event) => {
    event.preventDefault(); 
    let target = event.currentTarget.value; //current target gets lost on async. 
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/accountable/deletetemplate",
        "POST",
        JSON.stringify({
          templateid: event.currentTarget.value

        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      if (responseData.valid)
      {
        setTemplates(templates.filter(template=>template._id!==target)); //remove deleted object
      }
      }
      catch (err) {} 
  }

const handleChange = (event) =>{
    let target=event.target.value; 
    getTemplates(target);
}

  return (
    <div className={classes.root}>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Template"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      {(auth.accountableAdmin || auth.provider)&&           <TextField
            className={classes.textField}
            
            label="Filter By Template"
            name="filter"
            onChange={handleChange}
            variant="outlined"
          />}
      <AddTemplate open={showModal} close={setShowModal} editVal={editTemplate}/>
      <Grid
        container
        spacing={4}
      >

         <Grid
          item
          lg={12}
          sm={12}
          xl={8}
          xs={12}
     >
      <Card>
<CardContent>

        <Typography gutterBottom className={classes.title} color="textSecondary" variant="h3">Templates</Typography>
            <Grid container justify="flex-end">
          <Button color="secondary" onClick={addTemplate}>
            Add Template&nbsp;&nbsp;
            <AddCircleIcon color="secondary" fontSize="small" />
      </Button> 
      
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Text</TableCell>
                 <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {templates && templates.map(template=> {

                  return <TableRow button key={template._id}>
                <TableCell style={{ verticalAlign: 'top' }}><Button color="secondary" value={template.template} key={template._id} onClick={props.setVal}><Typography color="primary" variant="h6" gutterBottom>{template.templateName}</Typography></Button></TableCell>
                <TableCell style={{ verticalAlign: 'top' }}>{template.template}</TableCell>
                <TableCell style={{ verticalAlign: 'top' }}><Button variant="contained" color="secondary" value={template._id} key={template._id} onClick={deleteTemplate}><DeleteIcon></DeleteIcon></Button></TableCell>
              </TableRow> })}
            </TableBody>
          </Table>
        </TableContainer>
        </CardContent>
        </Card>
        </Grid>
        </Grid>

    </div>
  );
};

export default Templates;
