"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "confirmable", {
  enumerable: true,
  get: function get() {
    return _confirmable["default"];
  }
});
Object.defineProperty(exports, "createConfirmation", {
  enumerable: true,
  get: function get() {
    return _createConfirmation["default"];
  }
});
Object.defineProperty(exports, "createConfirmationCreater", {
  enumerable: true,
  get: function get() {
    return _createConfirmation.createConfirmationCreater;
  }
});
Object.defineProperty(exports, "createDomTreeMounter", {
  enumerable: true,
  get: function get() {
    return _domTree.createDomTreeMounter;
  }
});
Object.defineProperty(exports, "createMountPoint", {
  enumerable: true,
  get: function get() {
    return _reactTree.createMountPoint;
  }
});
Object.defineProperty(exports, "createReactTreeMounter", {
  enumerable: true,
  get: function get() {
    return _reactTree.createReactTreeMounter;
  }
});

var _confirmable = _interopRequireDefault(require("./confirmable"));

var _createConfirmation = _interopRequireWildcard(require("./createConfirmation"));

var _domTree = require("./mounter/domTree");

var _reactTree = require("./mounter/reactTree");

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }