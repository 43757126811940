import { useState, useEffect } from 'react';
import { AuthContextType } from '../../types/auth';

interface Member {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

interface SendRequestFunction {
  (url: string, method: string, body: any, headers: Record<string, string>): Promise<any>;
}

interface UseGetMembersResult {
  members: Member[] | undefined;
}

export const useGetMembers = (
  sendRequest: SendRequestFunction,
  auth: AuthContextType,
  showOnlyNumbers: boolean = false
): UseGetMembersResult => {
  const [members, setMembers] = useState<Member[] | undefined>();

  useEffect(() => {
    const getUsers = async () => {
      try {
        const responseData: Member[] = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/accountable/users`,
          'GET',
          null,
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );

        if (!showOnlyNumbers) {
          setMembers(
            responseData.sort((a, b) =>
              a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1
            )
          );
        } else {
          setMembers(
            responseData
              .filter((user) => user.phoneNumber)
              .sort((a, b) => (a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1))
          );
        }
      } catch (err) {
        console.error('Error fetching members:', err);
      }
    };

    getUsers();
  }, [sendRequest, auth.token, showOnlyNumbers]);

  return { members };
};
