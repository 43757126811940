import { useState, useEffect, useCallback, useRef } from 'react';
import Cookies from 'js-cookie';
import { AuthContextType, AuthState } from '../../types/auth';

export const useAuth = (): AuthContextType => {
  const [authState, setAuthState] = useState<AuthState>({
    token: null,
    userId: null,
    email: null,
    tenant: null,
    error: null,
    authProvider: false,
    stripeStatus: null,
    tokenExpirationDate: null,
    accountableAdmin: false,
    provider: false,
    programProvider: false,
    program: false,
    client: false,
    harambe: false,
    siteAdmin: false,
    wsClient: null,
    loading: true
  });

  const logOutTimer = useRef<NodeJS.Timeout>();

  const login: AuthContextType['login'] = useCallback(
    (
      uid,
      email,
      tenant,
      token,
      expirationDate,
      authprovider,
      status,
      accountableAdmin,
      siteAdmin,
      harambe,
      program,
      provider,
      programProvider
    ) => {
      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
      setAuthState((prevState) => ({
        ...prevState,
        token,
        userId: uid,
        email,
        tenant,
        authProvider: authprovider,
        stripeStatus: status,
        tokenExpirationDate,
        accountableAdmin,
        siteAdmin,
        harambe,
        program,
        provider,
        programProvider,
        loading: false
      }));

      localStorage.setItem(
        'userData',
        JSON.stringify({
          userId: uid,
          email,
          tenant,
          token,
          expiration: tokenExpirationDate.toISOString(),
          authProvider: authprovider,
          status,
          accountableAdmin,
          siteAdmin,
          harambe,
          programProvider,
          program,
          provider
        })
      );
    },
    []
  );

  const updateSettings: AuthContextType['updateSettings'] = useCallback(
    (company, email, stripe) => {
      setAuthState((prevState) => ({
        ...prevState,
        tenant: company || prevState.tenant,
        email: email || prevState.email,
        stripeStatus: stripe || prevState.stripeStatus
      }));
    },
    []
  );

  const logout: AuthContextType['logout'] = useCallback(() => {
    setAuthState((prevState) => ({
      ...prevState,
      token: null,
      tokenExpirationDate: null,
      userId: null,
      loading: false,
      accountableAdmin: false,
      harambe: false,
      wsClient: null
    }));
    if (authState.wsClient && authState.wsClient.readyState === WebSocket.OPEN) {
      authState.wsClient.close(1000, 'Closing connection on logout');
    }
    localStorage.removeItem('userData');
  }, [authState.wsClient]);

  const clearError: AuthContextType['clearError'] = useCallback((value) => {
    setAuthState((prevState) => ({ ...prevState, error: value }));
  }, []);

  useEffect(() => {
    const failData = Cookies.get('fail');
    if (failData) {
      setAuthState((prevState) => ({ ...prevState, error: failData, loading: false }));
      Cookies.set('fail', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7
      });
    }

    let storedData: any = Cookies.get('auth');
    if (storedData) {
      storedData = JSON.parse(storedData.replace('j:', ''));
      storedData.expiration = new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
      storedData.authProvider = false;
      Cookies.set('auth', 'test', {
        path: '/',
        domain: process.env.REACT_APP_COOKIEDOMAIN,
        expires: -7
      });
    }

    if (!storedData) {
      const localData = localStorage.getItem('userData');
      storedData = localData ? JSON.parse(localData) : null;
    }

    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(
        storedData.userId,
        storedData.email,
        storedData.tenant,
        storedData.token,
        new Date(storedData.expiration),
        storedData.authProvider,
        storedData.status,
        storedData.accountableAdmin,
        storedData.siteAdmin,
        storedData.harambe,
        storedData.program,
        storedData.provider,
        storedData.programProvider
      );
    } else {
      setAuthState((prevState) => ({ ...prevState, loading: false }));
    }
  }, [login]);

  useEffect(() => {
    if (authState.token && authState.tokenExpirationDate) {
      const remainingTime = authState.tokenExpirationDate.getTime() - new Date().getTime();
      logOutTimer.current = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logOutTimer.current);
    }

    return () => {
      if (logOutTimer.current) {
        clearTimeout(logOutTimer.current);
      }
    };
  }, [authState.token, authState.tokenExpirationDate, logout]);

  const INACTIVITY_TIMEOUT = 3500000; // 3500 seconds (about 58 minutes)

  useEffect(() => {
    const events = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
    let inactivityTimer: NodeJS.Timeout;

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(logout, INACTIVITY_TIMEOUT);
      localStorage.setItem('lastActivity', Date.now().toString());
    };

    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'lastActivity') {
        resetInactivityTimer();
      }
    };

    events.forEach((event) => window.addEventListener(event, resetInactivityTimer));
    window.addEventListener('storage', handleStorageChange);

    resetInactivityTimer();

    return () => {
      clearTimeout(inactivityTimer);
      events.forEach((event) => window.removeEventListener(event, resetInactivityTimer));
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [logout]);

  return {
    ...authState,
    isLoggedIn: authState.token !== null,
    login,
    logout,
    clearError,
    updateSettings,
    setAccountableAdmin: (value: boolean) =>
      setAuthState((prevState) => ({ ...prevState, accountableAdmin: value })),
    setSiteAdmin: (value: boolean) =>
      setAuthState((prevState) => ({ ...prevState, siteAdmin: value })),
    setHarambe: (value: boolean) => setAuthState((prevState) => ({ ...prevState, harambe: value })),
    setProvider: (value: boolean) =>
      setAuthState((prevState) => ({ ...prevState, provider: value })),
    setProgram: (value: boolean) => setAuthState((prevState) => ({ ...prevState, program: value })),
    setProgramProvider: (value: boolean) =>
      setAuthState((prevState) => ({ ...prevState, programProvider: value })),
    setClient: (value: boolean) => setAuthState((prevState) => ({ ...prevState, client: value })),
    setWsClient: (client: WebSocket | null) =>
      setAuthState((prevState) => ({ ...prevState, wsClient: client }))
  };
};
