import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Box
} from '@mui/material';
import { RemoveCircle } from '@mui/icons-material';

import LoadingSpinner from './LoadingSpinner';
import { useHttpClient } from '../hooks/http-hook';
import ErrorModal from './ErrorModal';
import VendorAlerts from '../../pages/VendorAlerts';
import ModalComponent from './ModalComponent';
import Sms from '../../pages/Sms';
import Tox from '../../pages/Tox';
import ARSFilter from './ARSFilter';

const NotificationsMenu = (props) => {
  const { isLoading, error, clearError } = useHttpClient();
  const [showBACAlerts, setShowBACAlerts] = useState(false);
  const [showSMS, setShowSMS] = useState(false);
  const [showTox, setShowTox] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const readNotification = async (event) => {
    event.preventDefault();
    const { value } = event.currentTarget;

    try {
      await fetch(process.env.REACT_APP_BACKEND_URL + '/api/notification/clearNotifications', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + props.auth.token
        },
        body: JSON.stringify({
          id: value
        })
      });
      const newNotifications = notifications.filter((notification) => notification._id !== value);
      setNotifications(newNotifications);
      props.onNotificationRead(newNotifications);
    } catch (err) {
      console.error(err);
    }
  };
  const closeAll = () => {
    setShowBACAlerts(false);
    setShowSMS(false);
    setSelectedUser(false);
    setShowTox(false);
  };

  useEffect(() => {
    setNotifications(props.notifications);
  }, [props.notifications]);

  return (
    <>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Team Member"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      {showTox && (
        <ModalComponent
          open={showTox}
          close={closeAll}
          title="Tox Alerts"
          ChildComponent={Tox}
          selectedUser={selectedUser}
          size="md"
        />
      )}
      {showBACAlerts && (
        <ModalComponent
          open={showBACAlerts}
          close={closeAll}
          title="BAC Alerts"
          ChildComponent={VendorAlerts}
          selectedUser={selectedUser}
          size="md"
        />
      )}
      {showSMS && (
        <ModalComponent
          open={showSMS}
          close={closeAll}
          title="Communication"
          ChildComponent={Sms}
          selectedUser={selectedUser}
          size="md"
        />
      )}
      <Card
        sx={{
          height: '100%'
        }}>
        <CardContent>
          <Typography
            sx={{
              fontWeight: 700
            }}
            color="textSecondary"
            variant="h6"
            gutterBottom>
            MY NOTIFICATIONS
          </Typography>
          {props.auth.accountableAdmin && (
            <Box mb={2}>
              <ARSFilter
                auth={props.auth}
                arsUser={props.arsUser}
                handleSetArsUser={props.handleSetArsUser}
                filterByARS={props.filterByARS}
                arsOnly
              />
            </Box>
          )}
          <List>
            {notifications &&
              notifications.map((notification) => (
                <ListItem
                  key={notification._id}
                  sx={{
                    marginBottom: 2,
                    borderRadius: 2,
                    backgroundColor: 'background.paper',
                    boxShadow: 2,
                    position: 'relative',
                    pl: 7
                  }}>
                  <Button
                    sx={{
                      position: 'absolute',
                      left: 8,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      minWidth: 'auto',
                      padding: 0.5
                    }}
                    onClick={readNotification}
                    value={notification._id}>
                    <RemoveCircle color="error" />
                  </Button>
                  <ListItemText
                    primary={<Typography variant="h4">{notification.notification}</Typography>}
                    secondary={
                      <>
                        <Typography variant="body1" color="textSecondary">
                          User timezone: {notification.User.timeZone}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          {new Date(notification.date).toLocaleDateString()}
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            marginTop: 1
                          }}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{
                              fontSize: { xs: '0.7rem', sm: '0.8rem' },
                              padding: { xs: '4px 8px', sm: '6px 16px' }
                            }}
                            onClick={() => {
                              setShowBACAlerts(true);
                              setSelectedUser(notification.User._id || notification.User);
                            }}>
                            BAC Records
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{
                              fontSize: { xs: '0.7rem', sm: '0.8rem' },
                              padding: { xs: '4px 8px', sm: '6px 16px' }
                            }}
                            onClick={() => {
                              setShowTox(true);
                              setSelectedUser(notification.User._id);
                            }}>
                            Tox Records
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            sx={{
                              fontSize: { xs: '0.7rem', sm: '0.8rem' },
                              padding: { xs: '4px 8px', sm: '6px 16px' }
                            }}
                            onClick={() => {
                              setShowSMS(true);
                              setSelectedUser(notification.User._id);
                            }}>
                            Communication
                          </Button>
                        </Box>
                      </>
                    }
                  />
                </ListItem>
              ))}
          </List>
          {props.notifications.length === 0 && (
            <Typography variant="body1" align="center">
              No Notifications For Now!
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default NotificationsMenu;
