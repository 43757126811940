import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ErrorModal = (props) => {
  const handleClose = () => {
    props.clearError();
  };
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth={props.size || 'sm'}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.alert}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          fullWidth={props.fullWidth || false}
          variant={props.variant || 'text'}
          color="primary"
          autoFocus>
          {props.buttonTitle || 'Close'}{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ErrorModal;
