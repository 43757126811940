import React, { useState, useEffect } from "react";
import { Button, ButtonGroup } from "@material-ui/core";

const Filter = (props) => {

  const adjustFilter = (event) => {
    let val = event.currentTarget ? event.currentTarget.value : event; 
    props.setSelectedButton(parseInt(val));
  };

  return (
    <ButtonGroup color="primary" variant="contained" size="small">
      <Button
        onClick={adjustFilter}
        value={0}
        color={props.selectedButton === 0 ? "secondary" : "primary"}
      >
        ALL
      </Button>
      {props.authContext.accountableAdmin && <Button
        onClick={adjustFilter}
        value={1}
        color={props.selectedButton === 1 ? "secondary" : "primary"}
      >
        ARS
      </Button>}
      <Button
        onClick={adjustFilter}
        value={2}
        color={props.selectedButton === 2 ? "secondary" : "primary"}
      >
        Active
      </Button>
      {!props.authContext.provider && <Button
        onClick={adjustFilter}
        value={6}
        color={props.selectedButton === 6 ? "secondary" : "primary"}
      >
        Trial
      </Button> }
      <Button
        onClick={adjustFilter}
        value={3}
        color={props.selectedButton === 3 ? "secondary" : "primary"}
      >
        Inactive
      </Button>
      {props.authContext.accountableAdmin &&  !props.sms &&<Button
        onClick={adjustFilter}
        value={7}
        color={props.selectedButton === 7 ? "secondary" : "primary"}
      >
        Programs</Button>}
      {props.authContext.accountableAdmin && !props.sms && <Button
        onClick={adjustFilter}
        value={4}
        color={props.selectedButton === 4 ? "secondary" : "primary"}
      >
        Providers
      </Button>}
      {props.authContext.accountableAdmin &&  !props.sms &&<Button
        onClick={adjustFilter}
        value={5}
        color={props.selectedButton === 5 ? "secondary" : "primary"}
      >
        Admins
      </Button>}
    </ButtonGroup>
  );
};

export default Filter;
