import { useState, useCallback, useRef, useEffect } from 'react';

export interface HttpClientHook {
  isLoading: boolean;
  error: string | null;
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit | null,
    headers?: HeadersInit
  ) => Promise<any>;
  clearError: () => void;
}

export const useHttpClient = (): HttpClientHook => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const activeHttpRequests = useRef<AbortController[]>([]);

  const sendRequest = useCallback(
    async (
      url: string,
      method: string = 'GET',
      body: BodyInit | null = null,
      headers: HeadersInit = {}
    ): Promise<any> => {
      try {
        setIsLoading(true);
        const httpAbortCtrl = new AbortController();
        activeHttpRequests.current.push(httpAbortCtrl);

        const response = await fetch(url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal
        });

        const responseData = await response.json();

        activeHttpRequests.current = activeHttpRequests.current.filter(
          (reqCtrl) => reqCtrl !== httpAbortCtrl
        );

        if (!response.ok) {
          throw new Error(responseData.message || 'An error occurred');
        }

        setIsLoading(false);
        return responseData;
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
        setIsLoading(false);
        throw err;
      }
    },
    []
  );

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return { isLoading, error, sendRequest, clearError };
};
