import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AuthContext } from "../../../common/context/auth-context";
import { useHttpClient } from "../../../common/hooks/http-hook";
import { Avatar, ListItemText } from "@material-ui/core";
import {useIntake} from "./intake-hook"; 

const Intake = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const { setIntakeForm, intakeForm } = useIntake(); 
  const handleClose = () => {
    props.close();
  };

  useEffect(() => {
    const getIntake = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/accountable/getintake",
          "POST",
          JSON.stringify({
            id: props.id,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        if (responseData) {
            setIntakeForm(Object.entries(responseData.intake));
        }
      } catch (err) {}
    };
    getIntake();
  }, [props.id, sendRequest]);

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{props.name}'s Intake</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <List>
              {intakeForm &&
                intakeForm.map(val => {
                    if (val) {
                  const newval = val.split(':');
                  return <ListItem ><ListItemText primary={newval[0]} secondary={newval[1]}/></ListItem>;
                    }
                  
                })}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            value={false}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default Intake;
