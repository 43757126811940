import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar, Badge, IconButton, Popover, Box } from '@mui/material';
import {
  Menu as MenuIcon,
  NotificationsOutlined as NotificationsIcon,
  Input as InputIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import NotificationsMenu from '../../../common/UI/NotificationMenu';
import LoadingSpinner from '../../../common/UI/LoadingSpinner';
import ErrorModal from '../../../common/UI/ErrorModal';
import { useNotifications } from '../../../common/hooks/notifications-hook';
import logoSrc from '../../../../public/images/white_logo_transparent_background.png';

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const [anchor, setAnchor] = useState(null);
  const { notifications, auth, isLoading, error, clearError } = useNotifications();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [topbarNotifications, setTopbarNotifications] = useState([]);

  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
  };

  const showNotifications = (event) => setAnchor(event.currentTarget);
  const handleClose = () => setAnchor(null);
  const onNotificationRead = (newNotifications) => {
    setTopbarNotifications(newNotifications);
  };

  useEffect(() => {
    setTopbarNotifications(notifications);
  }, [notifications]);

  return (
    <>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Team Member"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: '100%',
            maxWidth: 600,
            maxHeight: '90vh',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '95%'
            }
          }
        }}
        slotProps={{
          paper: {
            style: { marginTop: 8 }
          }
        }}
        marginThreshold={0}
        disableScrollLock={true}>
        <NotificationsMenu
          notifications={topbarNotifications}
          auth={auth}
          onNotificationRead={onNotificationRead}
        />
      </Popover>
      <AppBar
        {...rest}
        sx={{
          boxShadow: 'none'
        }}>
        <Toolbar>
          <RouterLink to="/">
            <Box component="img" src={logoSrc} alt="Logo" sx={{ height: 60 }} />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          {isMediumScreen ? (
            <Box sx={{ display: 'flex' }}>
              <IconButton
                sx={{ marginLeft: theme.spacing(2), color: 'white' }}
                onClick={showNotifications}>
                <Badge badgeContent={topbarNotifications.length} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                color="inherit"
                onClick={logoutHandler}
                sx={{ marginLeft: theme.spacing(2), color: 'white' }}>
                <InputIcon />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              sx={{
                marginLeft: theme.spacing(2),
                color: 'white'
              }}
              onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Topbar;
