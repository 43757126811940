import { useState, useEffect, ChangeEvent } from 'react';
import validate from 'validate.js';

interface FormState<T> {
  isValid: boolean;
  values: T;
  touched: { [K in keyof T]?: boolean };
  errors: { [K in keyof T]?: string[] };
}

type ValidateSchema = Record<string, any>;

export const useForm = <T extends Record<string, any>>(schema: ValidateSchema) => {
  const [formState, setFormState] = useState<FormState<T>>({
    isValid: false,
    values: {} as T,
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values, schema]);

  const clearFormState = () => {
    setFormState({ isValid: false, values: {} as T, touched: {}, errors: {} });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.persist) {
      event.persist();
    }

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const hasError = (field: keyof T): boolean =>
    !!(formState.touched[field] && formState.errors[field]);

  return { hasError, handleChange, formState, clearFormState };
};
