import React, { useContext, useState, useEffect } from "react";
import ErrorModal from "../../../common/UI/ErrorModal";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "../../../common/hooks/form-hook";
import { AuthContext } from "../../../common/context/auth-context";
import LoadingSpinner from "../../../common/UI/LoadingSpinner";
import { useHttpClient } from "../../../common/hooks/http-hook";
import { makeStyles } from "@material-ui/styles";

const schema = {
  template: {
    presence: { allowEmpty: false, message: "is required" },

  },
  templateName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddTemplate = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const { hasError, handleChange, formState } = useForm(schema);
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/api/accountable/addtemplate",
        "POST",
        JSON.stringify({
          template: formState.values.template,
          templateName: formState.values.templateName
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}
    setOpen(false);
    props.close(false);
  };
  const handleCancel = (event) => {
    event.preventDefault();
    setOpen(false);
    props.close(false);
  };
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  return (
    <React.Fragment>
      {error && (
        <ErrorModal
          open={!!error}
          title="Error Creating Template"
          alert={error}
          clearError={clearError}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Add a Template</DialogTitle>
        <DialogContent>
        <TextField
            className={classes.textField}
            error={hasError("templateName")}
            fullWidth
            helperText={
              hasError("templateName") ? formState.errors.templateName[0] : null
            }
            label="Template Name"
            name="templateName"
            onChange={handleChange}
            value={formState.values.templateName || ""}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            error={hasError("template")}
            fullWidth
            helperText={
              hasError("template") ? formState.errors.template[0] : null
            }
            label="Template"
            name="template"
            onChange={handleChange}
            value={formState.values.template || ""}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            autoFocus
            disabled={!formState.isValid}
          >
            Save Template
          </Button>
          <Button onClick={handleCancel} variant="contained" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddTemplate;
