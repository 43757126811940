import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import toast from 'react-hot-toast';
import { AuthContext } from './auth-context';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  const [socket, setSocket] = useState(null);

  const setupSocket = useCallback(() => {
    if (!auth.accountableAdmin) {
      return undefined;
    }
    let openCount = 1;
    const SOCKET_URL = process.env.REACT_APP_BACKEND_URL_WS;

    const socketInstance = new ReconnectingWebSocket(SOCKET_URL);

    socketInstance.addEventListener('open', () => {
      socketInstance.send(
        JSON.stringify({
          event: 'newConnection',
          token: auth.token,
          openCount
        })
      );
      openCount += 1;
    });

    socketInstance.addEventListener('close', () => {
      console.log('socket connection closed');
      setSocket(null);
    });

    socketInstance.addEventListener('message', (message) => {
      if (message.data === 'PING') {
        setTimeout(() => {
          socketInstance.send(JSON.stringify({ event: 'PONG', token: auth.token }));
        }, 10000);
      } else if (message?.data.includes('Internal server error')) {
        console.log('socket error');
      } else {
        const { name } = JSON.parse(message.data);
        toast.success(`New message from: ${name}`);
        new Audio('https://accountableimages.s3.amazonaws.com/message.mp3').play();
      }
    });

    setSocket(socketInstance);

    return () => {
      socketInstance?.close();
    };
  }, [auth.accountableAdmin, auth.token]);

  useEffect(() => {
    if (!auth.isLoggedIn && socket) {
      socket.close();
    } else if (!socket && auth.isLoggedIn) {
      setupSocket();
    }

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [auth.isLoggedIn, setupSocket, socket]);

  return <WebSocketContext.Provider value={socket}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
