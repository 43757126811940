import  { useState, useEffect } from "react";

export const useIntake = () => {
  const [intakeObject, setIntakeForm] = useState([]);
  const [intakeForm, setIntakeArray] = useState([]);

  useEffect(() => {
    let intakeArray = [];
    intakeArray=
      intakeObject.map(([key, value]) => {
        try {
        switch (key) {
          case "q4_address":
            return (
              "Address: " +
              value.addr_line1 +
              ", " +
              value.addr_line2 +
              ", " +
              value.city +
              "," +
              value.state +
              " " +
              value.postal
            );
            break;
          case "q5_dateOf": return "DOB: "+value.month+"/"+value.day+"/"+value.year; 
            break;
          case "q6_email": return "Email: "+value;  
            break;
          case "q9_phone": return "Phone: "+value.full; 
            break;
          case "q56_howDid": return "Referred By: "+value; 
            break;
          case "q60_whatType": return "What type of treatment have you received: "+value.map(val => val+", ");
            break;
          case "q63_areYou63": return "Are you currently sober: "+value; 
          break;
          case "q67_whenWas": return "Sober Date: "+value.month+"/"+value.day+"/"+value.year; 
          case "q95_whatAre": try {return "What are your substances of choice: "+value.map(val => val+", ") || value.other;} catch (err){return "What are your substances of choice: "}
          case "q97_areYou": try { return "Are you currently on any medication: "+Object.entries(value).map(([key,val]) =>  value[key]+", ")} catch(err){return "Are you currently on any medication: "}
          case "q98_areYou98": return "Are you currently in any treatment: "+value.map(val => val+", ");
          case "q68_doYou68": return "Physical Health Goals: "+value;
          case "q70_occupation": return "Occupation: "+value;
          case "q71_whatGoals": return "Occupational Goals: "+value;
          case "q87_doYou": return "12 Step or Other Peer Support Programs: "+value;
          case "q88_ifYou88": return "Peer Support Involvment: "+value;
          case "q89_usExercise89": return "Do you exercise: "+value;
          case "q73_howMany": return "Exercise frequency: "+value;
          case "q90_isMeditation": return "Do you meditate: "+value;
          case "q76_howMany76": return "Meditation frequency: "+value;
          case "q77_whatDoes": return "What does self care look like: "+value;
          case "q79_ifThere": return "How do you want to improve self care?: "+value;
          case "q80_areThere80": return "Family Dynamic Goals: "+value;
          case "q92_whyDo": return "Why do you want to get sober: "+value; 
          case "q78_isThere": return "Is there anythign else we should know: "+value; 
          case "q83_accountableEmergency": return "Contact:  "+value.last+", "+value.first; 
          case "q84_accountableEmergency84": return "Contact Phone: "+value.full; 
          case "q85_doWe85": return "Permission to contact with tox: "+value;
          case "q86_doWe86":return "Permission to contact with checkin: "+value;
          case "q50_typeA": return "Agreement Accepted: "+value;
          case "q104_whatAre104": return "Pronouns: "+value;
          case "q105_whatIs": return "Sexual Orientation: "+ value; 
          case "q103_residentialDischarge": return "Residential Discharge Date: "+value.month+"/"+value.day+"/"+value.year; 
          case "q52_signature": break;       
        }
      }
      catch (err) {
        console.log(err); 
      }
      });
    
    setIntakeArray(intakeArray);
  }, [intakeObject]); //parse intake object when changed

  return { setIntakeForm, intakeForm };
};
