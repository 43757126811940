import React from 'react';
import { Divider, Drawer, Typography, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import InputIcon from '@mui/icons-material/Input';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SmsIcon from '@mui/icons-material/Sms';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SidebarNav from './SidebarNav';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import AssessmentIcon from '@mui/icons-material/Assessment';
import PieChartIcon from '@mui/icons-material/PieChart';
import PeopleRounded from '@mui/icons-material/PeopleRounded';
import MailIcon from '@mui/icons-material/Mail';
import { Visibility } from '@mui/icons-material';

export interface PageItem {
  title: string;
  href: string;
  icon: React.ReactNode;
  admin?: boolean;
  Program?: boolean;
  siteAdmin?: boolean;
  User?: boolean;
  hide?: boolean;
  logout?: boolean;
}

interface SidebarProps {
  open: boolean;
  variant: 'permanent' | 'persistent' | 'temporary';
  onClose: () => void;
  className?: string;
  tenantname: string;
}

const Sidebar: React.FC<SidebarProps> = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const pages: PageItem[] = [
    {
      title: 'My Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Outcomes Analytics',
      href: '/analytics',
      icon: <PieChartIcon />,
      admin: true,
      Program: true,
      siteAdmin: true
    },
    {
      title: 'Onboarding',
      href: '/onboarding',
      icon: <PeopleRounded />,
      admin: true
    },
    {
      title: 'Ask AJ',
      href: '/accountableGPT',
      icon: <LiveHelpIcon />,
      admin: true
    },
    {
      title: 'Member Reports',
      href: '/reports',
      icon: <AssessmentIcon />,
      admin: true
    },
    {
      title: 'Wellness Goal Plan',
      href: '/goal',
      icon: <CheckCircleIcon />
    },
    {
      title: 'Goal Library',
      href: '/goallibrary',
      icon: <LocalLibraryIcon />
    },
    {
      title: 'Safety Plan',
      href: '/safety',
      icon: <ReportProblemIcon />,
      User: true
    },
    {
      title: 'Communication Log',
      href: '/sms',
      icon: <SmsIcon />
    },

    /* {
      title: 'Community Chat',
      href: '/groupchat',
      icon: <GroupIcon />
    },*/
    {
      title: 'Survey Results',
      href: '/surveyresults',
      icon: <ShowChartIcon />
    },

    {
      title: 'Scheduled Messages',
      href: '/schedule',
      icon: <AccessAlarmIcon />,
      admin: true
    },
    {
      title: 'Manage Surveys',
      href: '/surveyadmin',
      icon: <LiveHelpIcon />,
      admin: true
    },
    {
      title: 'Toxicology Results',
      href: '/tox',
      icon: <FavoriteIcon />
    },
    {
      title: 'Toxicology Reviews',
      href: '/tox-reviews',
      icon: <Visibility />,
      admin: true
    },
    {
      title: 'Breathalyzer Results',
      href: '/vendoralerts',
      icon: <RecordVoiceOverIcon />
    },
    {
      title: 'My Members',
      href: '/adminportal',
      icon: <PeopleIcon />,
      hide: true
    },
    {
      title: 'Kits',
      href: '/kits',
      icon: <MailIcon />,
      admin: true
    },
    {
      title: 'Orders',
      href: '/orders',
      icon: <MailIcon />,
      admin: true
    },
    {
      title: 'Care Team Members',
      href: '/Users',
      icon: <PeopleIcon />,
      User: true,
      Program: true,
      siteAdmin: true
    },
    {
      title: 'Account Settings',
      href: '/account',
      icon: <AccountBoxIcon />
    },
    {
      title: 'Audits',
      href: '/audits',
      icon: <AssessmentIcon />,
      admin: true
    },
    {
      title: 'GEHA Reports',
      href: '/insurance-reports',
      icon: <AssessmentIcon />,
      admin: true
    },
    {
      title: 'Logout',
      href: '/sign-in',
      logout: true,
      icon: <InputIcon />
    }
  ];

  return (
    <Drawer
      anchor="left"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          top: { lg: 64 },
          height: { lg: 'calc(100% - 64px)' }
        }
      }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          p: 2
        }}
        {...rest}>
        <Typography align="left" variant="h5" sx={{ ml: 1 }}>
          {props.tenantname}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <SidebarNav sx={{ mb: 2 }} pages={pages} />
      </Box>
    </Drawer>
  );
};

export default Sidebar;
