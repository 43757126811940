/* eslint-disable no-use-before-define */
import React, { useEffect, useContext, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  TextField,
  Button as ReactButton
} from '@material-ui/core';
import { MessageList, ChatList, Input, Button } from 'react-chat-elements';
import LoopIcon from '@material-ui/icons/Loop';
import NotesIcon from '@material-ui/icons/Notes';
import InfoIcon from '@material-ui/icons/Info';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ErrorModal from '../common/UI/ErrorModal';
import { AuthContext } from '../common/context/auth-context';

import LoadingSpinner from '../common/UI/LoadingSpinner';
import { useHttpClient } from '../common/hooks/http-hook';
import 'react-chat-elements/dist/main.css';
import Templates from './components/communication/templates';
import ReplyRequired from './components/communication/reply-required';

import { useGetFilters } from '../common/hooks/getfilters-hook';
import Filter from '../common/UI/Filter';
import CareTeamModal from '../common/UI/CareTeamModal';
import Intake from './components/adminportal/intake';
import Notes from './components/adminportal/notes';
import ARSFilter from '../common/UI/ARSFilter';
import favIco from '../../public/images/logo_size.jpg';
import { useWebSocket } from '../common/context/web-socket-context';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(4) },
  button: { marginRight: theme.spacing(3) },
  content: { alignItems: 'center', display: 'flex' },
  title: { fontWeight: 700 },
  divider: { margin: theme.spacing(2, 0) }
}));

let clearRef = () => {};

function Sms(props) {
  const auth = useContext(AuthContext);
  const socket = useWebSocket();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [typedMessage, setTypedMessage] = useState('');
  const inputRef = useRef();

  const [selectedUser, setSelectedUser] = useState();
  const [messages, setMessages] = useState();
  const [showReplyRequired, setShowReplyRequired] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState();
  const [messageCount, setMessageCount] = useState(1);
  const [rerender, setReRender] = useState(false);
  const {
    adminUsers,
    getAdminUsers,
    setAdminUsers,
    setSelectedButton,
    selectedButton,
    filterByARS,
    handleSetArsUser,
    arsUser
  } = useGetFilters(sendRequest, auth, showModal, true, null, rerender, null, setReRender);
  const [showIntakeForm, setShowIntakeForm] = useState(false);
  const [intakeName, setIntakeName] = useState(false);
  const [notesFormName, setNotesFormName] = useState(false);
  const [showNotesForm, setShowNotesForm] = useState(false);
  const [careTeam, setCareTeam] = useState(false); // show care team
  const [careTeamName, setCareTeamName] = useState();
  const [careTeamID, setCareTeamID] = useState();
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (props.selectedUser && adminUsers) {
      setSelectedButton(2);
      const user = adminUsers.find((users) => users._id === props.selectedUser);
      if (user) {
        const myUser = {
          alt: `${user.firstName} ${user.lastName}`,
          title: `${user.firstName} ${user.lastName}`,
          firstName: user.firstName,
          lastName: user.lastName,
          subtitle: user.phoneNumber || 'No Number Entered',
          timeZone: user.timeZone || 'No TZ Selected',
          id: user._id,
          formId: user.formId,
          tenant: user.tenant,
          toxes: user.toxes,
          labScreens: user.labScreens,
          unread: auth.accountableAdmin ? user.smsUnread : '0',
          renewalDate: user.billingCycleEnd
        };
        setSelectedUser(myUser);
        getSMS(user._id, 1);
      }
    }
  }, [adminUsers, auth.accountableAdmin, props.selectedUser]);

  useEffect(() => {
    if (!auth.accountableAdmin || !auth.provider) getCurrentUser();
  }, [sendRequest, showModal]);

  const handleChange = (event) => {
    const target = event.target.value;
    setFilterText(target);
    if (target.length > 2) getAdminUsers(target);
    else if (target.length < (filterText && filterText.length)) getAdminUsers(target);
  };

  const getCurrentUser = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/accountable/getcurrentuser`,
        'GET',
        null,
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      );
      if (responseData && !responseData.message) {
        setSelectedUser({
          avatar: favIco,
          alt: `${responseData.firstName} ${responseData.lastName}`,
          title: `${responseData.firstName} ${responseData.lastName}`,
          subtitle: responseData.phoneNumber || 'No Number Entered',
          timeZone: responseData.timeZone || 'No Timezone Selected',
          id: responseData._id,
          unread: 0
        });
        getSMS(responseData._id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSMS = useCallback(
    async (id, messageCount) => {
      try {
        const smsDataRequest = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/sms/getsms`,
          'POST',
          JSON.stringify({ id, messageCount }),
          {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.token}`
          }
        );
        const smsData = smsDataRequest || null;
        if (smsData) {
          setMessages(mapSmsDataToMessages(smsData));
          setTypedMessage('');
          if (auth.accountableAdmin)
            // no input for non admin
            clearRef();
        }
      } catch (err) {
        console.error(err);
      }
    },
    [auth, sendRequest]
  );

  const mapSmsDataToMessages = (smsData) => {
    return smsData.map((sms) => {
      if (!sms.photoURL[0] && !sms.videoURL[0])
        return {
          title: sms.accountable
            ? sms.UserFrom
              ? `${sms.UserFrom.firstName} ${sms.UserFrom.lastName}`
              : 'Accountable'
            : `${sms.User.firstName} ${sms.User.lastName}`,
          position: sms.accountable ? 'right' : 'left',
          type: 'text',
          text: sms.message,
          date: new Date(sms.date),
          mid: sms._id
        };
      if (sms.photoURL[0]) {
        return {
          title: sms.accountable
            ? sms.UserFrom
              ? `${sms.UserFrom.firstName} ${sms.UserFrom.lastName}`
              : 'Accountable'
            : `${sms.User.firstName} ${sms.User.lastName}`,
          position: sms.accountable ? 'right' : 'left',
          type: 'photo',
          data: { uri: sms.photoURL[0], width: '200px', height: '200px' },
          text: sms.photoURL?.map((photo) => (
            <>
              {sms.message && sms.message !== 'Media Message' && (
                <>
                  {sms.message}
                  <br />
                </>
              )}
              <a href={photo} target="_blank" rel="noreferrer">
                Open Image
              </a>
              <br />
            </>
          )),
          date: new Date(sms.date),
          mid: sms._id
        };
      }
      if (sms.videoURL[0]) {
        return {
          title: sms.accountable
            ? sms.UserFrom
              ? `${sms.UserFrom.firstName} ${sms.UserFrom.lastName}`
              : 'Accountable'
            : `${sms.User.firstName} ${sms.User.lastName}`,
          position: sms.accountable ? 'right' : 'left',
          type: 'video',
          data: {
            uri: sms.videoURL[0],
            width: '200px',
            height: '200px',
            videoURL: sms.videoURL[0]
          },
          text: sms.videoURL?.map((video) => (
            <>
              <a href={video} target="_blank" rel="noreferrer">
                Open Video
              </a>
              <br />
            </>
          )),
          date: new Date(sms.date),
          mid: sms._id
        };
      }
    });
  };

  const getMoreMessages = useCallback(() => {
    if (selectedUser) {
      getSMS(selectedUser.id, messageCount + 1);
      setMessageCount(messageCount + 1);
    }
  }, [selectedUser, getSMS, messageCount]);

  useEffect(() => {
    const handleMessage = async (message) => {
      const { data } = message;
      if (data === 'PING') {
        return;
      }
      const { event, from } = JSON.parse(data);

      if (event === 'sendMessage') {
        // update existing user with new unread count
        const users = [...adminUsers]; // copy the array instead of mutating it
        const userIndex = users.findIndex((user) => user._id === from);
        if (userIndex !== -1) {
          users[userIndex].smsUnread = users[userIndex].smsUnread
            ? users[userIndex].smsUnread + 1
            : 1;
          setAdminUsers(users);
        }

        if (selectedUser.id === from) {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sms/getsms`, {
            method: 'POST',
            body: JSON.stringify({ id: selectedUser.id, messageCount: 1 }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`
            }
          });
          const responseData = await response.json();
          console.log(responseData);
          if (responseData) {
            const messages = mapSmsDataToMessages(responseData);
            setMessages(messages);
          }
        }
      }
    };

    if (!socket) return undefined;

    socket.addEventListener('message', handleMessage);

    return () => {
      socket.removeEventListener('message', handleMessage);
    };
  }, [socket, selectedUser, auth.token, adminUsers, setAdminUsers]);

  const saveSelectedUser = async (event) => {
    setSelectedUser(event);
    getSMS(event.id);
    setMessageCount(1);
    setTypedMessage('');
    if (auth.accountableAdmin) {
      clearRef();
    }
    await getAdminUsers(filterText); // refresh unread count
  };
  const sendMessage = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/sms/sendsms`,
        'POST',
        JSON.stringify({
          text: typedMessage,
          to: selectedUser.subtitle,
          id: selectedUser.id,
          reply: true
        }),
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}` // send the token
        }
      );
      if (responseData.message) {
        setMessages((messages) => [
          ...messages,
          {
            title: 'Accountable',
            position: 'right',
            type: 'text',
            text: typedMessage,
            date: new Date(),
            mid: responseData.message
          }
        ]);
        setTypedMessage('');
        clearRef();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const sendMessageNoReply = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/sms/sendsms`,
        'POST',
        JSON.stringify({
          text: typedMessage,
          to: selectedUser.subtitle,
          id: selectedUser.id,
          reply: false
        }),
        {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.token}`
        }
      );
      if (responseData.message) {
        setMessages((messages) => [
          ...messages,
          {
            title: auth.tenant || 'Accountable',
            position: 'right',
            type: 'text',
            text: typedMessage,
            date: new Date(),
            mid: responseData.message
          }
        ]);
        setTypedMessage('');
        clearRef();
      }
    } catch (err) {
      console.error(err);
    }
  };
  const setTemplateValue = (event) => {
    // Set the new value in your component state
    setTypedMessage(event.currentTarget.value);
    inputRef.current.value = event.currentTarget.value;
    inputRef.current.onChange({
      FAKE_EVENT: true,
      target: inputRef.current
    });
    inputRef.current.focus();
  };

  const closeReplyRequired = () => {
    setShowReplyRequired(false);
    setSelectedMessage(null);
  };
  const replyClick = (event) => {
    setShowReplyRequired(event.mid);
    setSelectedMessage(
      `${new Date(event.date).toLocaleDateString()} ${new Date(event.date).toLocaleTimeString()}: ${
        event.text
      }`
    );
  };
  const closeIntakeForm = () => {
    setShowIntakeForm(false);
    setIntakeName(false);
  };
  const openIntakeForm = (event) => {
    const values = event.currentTarget.value.split(',');
    setShowIntakeForm(values[0]);
    setIntakeName(values[1]);
  };
  const closeNotesForm = () => {
    setShowNotesForm(false);
    setNotesFormName(false);
  };
  const openNotesForm = (event) => {
    const values = event.currentTarget.value.split(',');
    setShowNotesForm(values[0]);
    setNotesFormName(values[1]);
  };
  const showCareTeam = (event) => {
    const values = event.currentTarget.value.split(',');
    setCareTeam(values[0]);
    setCareTeamName(values[1]);
    setCareTeamID(values[2]);
  };
  const clearCareTeam = () => {
    setCareTeam(null);
    setCareTeamName(null);
    setCareTeamID(null);
  };

  return (
    <div className={classes.root}>
      {error && (
        <ErrorModal
          open={!!error}
          title="Cannot Retrieve Messages"
          alert={error}
          clearError={clearError}
        />
      )}
      {auth.accountableAdmin && (
        <ReplyRequired
          open={!!showReplyRequired}
          mid={showReplyRequired}
          message={selectedMessage}
          close={closeReplyRequired}
        />
      )}
      {isLoading && <LoadingSpinner open={isLoading} />}
      {(auth.accountableAdmin || auth.provider) && (
        <TextField
          className={classes.textField}
          label="Filter By Last Name"
          name="filter"
          onChange={handleChange}
          variant="outlined"
        />
      )}
      {auth.accountableAdmin && showIntakeForm && (
        <Intake
          open={!!showIntakeForm}
          id={showIntakeForm}
          close={closeIntakeForm}
          name={intakeName}
        />
      )}
      {auth.accountableAdmin && showNotesForm && (
        <Notes
          open={!!showNotesForm}
          id={showNotesForm}
          close={closeNotesForm}
          name={notesFormName}
        />
      )}
      {auth.accountableAdmin && (
        <CareTeamModal
          open={!!careTeam}
          id={careTeam}
          clear={clearCareTeam}
          name={careTeamName}
          uid={careTeamID}
        />
      )}
      <Grid container spacing={4}>
        {(auth.accountableAdmin || auth.provider) && !props.selectedUser && (
          <Grid item lg={3} sm={12} xl={3} xs={12}>
            <Card>
              <CardContent>
                <Filter
                  selectedButton={selectedButton}
                  setSelectedButton={setSelectedButton}
                  authContext={auth}
                  sms
                />
                <Typography variant="h4" color="primary">
                  Members
                </Typography>
                {auth.accountableAdmin && (
                  <ARSFilter
                    auth={auth}
                    arsUser={arsUser}
                    handleSetArsUser={handleSetArsUser}
                    filterByARS={filterByARS}
                  />
                )}
                <ChatList
                  onClick={saveSelectedUser}
                  className="chat-list"
                  dataSource={adminUsers?.map((user) => {
                    return {
                      avatar: favIco,
                      alt: `${user.firstName} ${user.lastName}`,
                      title: `${user.firstName} ${user.lastName}`,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      subtitle: user.phoneNumber || 'No Number Entered',
                      timeZone: user.timeZone || 'No Timezone Selected',
                      id: user._id,
                      formId: user.formId,
                      tenant: user.tenant,
                      toxes: user.toxes,
                      labScreens: user.labScreens,
                      unread: auth.accountableAdmin ? user.smsUnread : '0',
                      renewalDate: user.billingCycleEnd
                    };
                  })}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid
          item
          lg={props.selectedUser ? 12 : 7}
          sm={12}
          xl={props.selectedUser ? 12 : 7}
          xs={12}>
          <Card>
            <CardContent>
              <Typography gutterBottom className={classes.title} color="textSecondary" variant="h3">
                {selectedUser
                  ? `${
                      selectedUser.title
                    }'s Communication Log (${selectedUser.timeZone.toUpperCase()})`
                  : 'Please Select a User'}
                {selectedUser && selectedUser.toxes
                  ? ` - ${selectedUser.toxes} Instant Tox Left`
                  : ''}
                {selectedUser && selectedUser.labScreens
                  ? ` - ${selectedUser.labScreens} Lab Test Left`
                  : ''}
                {selectedUser && auth.accountableAdmin && selectedUser.renewalDate
                  ? ` - Renewal Date: ${new Date(selectedUser.renewalDate).toLocaleDateString()}`
                  : ''}
              </Typography>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  {selectedUser && auth.accountableAdmin && (
                    <ReactButton
                      variant="contained"
                      disabled={!selectedUser.formId}
                      color="secondary"
                      value={[
                        selectedUser.id,
                        `${selectedUser.firstName} ${selectedUser.lastName}`
                      ]}
                      key={selectedUser.id}
                      onClick={openIntakeForm}>
                      <InfoIcon /> Intake
                    </ReactButton>
                  )}
                </Grid>
                <Grid item>
                  {selectedUser && auth.accountableAdmin && (
                    <ReactButton
                      variant="contained"
                      color="secondary"
                      value={[
                        selectedUser.id,
                        `${selectedUser.firstName} ${selectedUser.lastName}`
                      ]}
                      key={selectedUser.id}
                      onClick={openNotesForm}>
                      <NotesIcon /> Notes
                    </ReactButton>
                  )}
                </Grid>
                <Grid item>
                  {selectedUser && auth.accountableAdmin && (
                    <ReactButton
                      variant="contained"
                      color="secondary"
                      value={[
                        selectedUser.tenant,
                        `${selectedUser.firstName} ${selectedUser.lastName}`,
                        selectedUser.id
                      ]}
                      key={selectedUser.tenant}
                      onClick={showCareTeam}>
                      <GroupWorkIcon /> Care Team
                    </ReactButton>
                  )}
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              {selectedUser && (
                <Grid container justify="center">
                  <ReactButton color="primary" onClick={getMoreMessages} variant="contained">
                    See Prior Messages&nbsp;&nbsp;
                    <LoopIcon color="secondary" fontSize="small" />
                  </ReactButton>
                </Grid>
              )}
              {selectedUser && <Divider className={classes.divider} />}
              <MessageList
                className="message-list"
                lockable
                toBottomHeight="100%"
                dataSource={messages || []}
                onClick={auth.accountableAdmin ? replyClick : null}
              />
              <Input
                placeholder="Type here..."
                value={typedMessage}
                multiline
                onChange={(e) => setTypedMessage(e.target.value)}
                referance={inputRef}
                // eslint-disable-next-line no-return-assign
                clear={(clear) => (clearRef = clear)}
                rightButtons={
                  auth.accountableAdmin ? (
                    <>
                      <Button onClick={sendMessage} backgroundColor="secondary" text="Send" />
                      <Button
                        onClick={sendMessageNoReply}
                        backgroundColor="primary"
                        text="Send No Response"
                      />
                    </>
                  ) : (
                    <Button onClick={sendMessageNoReply} backgroundColor="secondary" text="Send" />
                  )
                }
              />
            </CardContent>
          </Card>
          {auth.accountableAdmin && <Templates setVal={setTemplateValue} />}
        </Grid>
      </Grid>
    </div>
  );
}

export default Sms;
