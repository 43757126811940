import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const ModalComponent = ({
  size,
  open,
  title,
  fullWidth,
  variant,
  close,
  buttonTitle,
  ChildComponent,
  ...otherProps
}) => {
  const handleClose = () => {
    close();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <ChildComponent {...otherProps} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          fullWidth={fullWidth || false}
          variant={variant || 'text'}
          color="primary"
          autoFocus>
          {buttonTitle || 'Close'}{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ModalComponent;
