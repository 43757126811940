import React, { useState } from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Topbar from './components/Topbar';

const Main = (props) => {
  const { children } = props;

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isMediumScreen ? true : openSidebar;

  const styles = {
    root: {
      paddingTop: isMediumScreen ? 64 : 56,
      height: '100%'
    },
    shiftContent: {
      paddingLeft: 240
    },
    content: {
      height: '100%'
    }
  };

  return (
    <div
      style={{
        ...styles.root,
        ...(isMediumScreen ? styles.shiftContent : {})
      }}
      className={clsx({
        root: true,
        shiftContent: isMediumScreen
      })}>
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isMediumScreen ? 'persistent' : 'temporary'}
        tenantname={props.tenantname}
      />
      <main style={styles.content}>
        {children}
        <Footer />
      </main>
    </div>
  );
};

export default Main;
