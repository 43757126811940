import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import palette from '../../../theme/palette';

interface Tox {
  videoKey: string;
  Notes: string;
}

interface ToxReviewModalProps {
  open: boolean;
  onClose: () => void;
  tox: Tox | null;
  onApprove: () => void;
  onReject: () => void;
}

const ToxReviewModal: React.FC<ToxReviewModalProps> = ({
  open,
  onClose,
  tox,
  onApprove,
  onReject
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4
        }}>
        <video width="100%" controls>
          <source src={tox?.videoKey} type="video/mp4" />
        </video>
        <Typography variant="h6" component="h2">
          Notes:{' '}
          {tox?.Notes.split('\n').map((note, index) => (
            <span key={index}>
              {note}
              <br />
            </span>
          ))}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button onClick={onApprove} variant="contained" color="primary">
            Approve
          </Button>
          <Button
            onClick={onReject}
            variant="contained"
            style={{
              backgroundColor: palette.error.main,
              color: palette.error.contrastText
            }}>
            Reject
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ToxReviewModal;
