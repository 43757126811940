import { useState, useEffect, useContext } from "react";
import {AuthContext} from '../context/auth-context';
import { useHttpClient } from "./http-hook";

export const useNotifications = () =>{
    const [notifications, setNotifications] = useState([]);
    const [renderCount, setRenderCount] = useState(0); //hack to force re-render when notification read. 
    const auth = useContext(AuthContext); 
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [filterByARS, setFilterByARS] = useState([]); //for ARS filter
    const [arsUser, setARSUser] = useState(0); //for ARS filter
    useEffect(() => {
      //get users for tenant
      const getNotifications = async () => {
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "/api/notification/getNotifications",
            "GET",
            null,
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
          if (responseData) setNotifications(responseData);
        } catch (err) {}
      };
      const getNotificationsByUser = async () => {
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "/api/notification/getNotificationsByUser",
            "POST",
            JSON.stringify({
              NotifiedUser: arsUser
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
          if (responseData) setNotifications(responseData);
        } catch (err) {}
      };
      if (!arsUser) getNotifications();
      if (arsUser) getNotificationsByUser();
    }, [renderCount, arsUser]);
  
    const reRender = async () => {
        let count = renderCount; 
        count++; 
        setRenderCount(count); 
     }

     const handleSetArsUser = (event) => {
      event.preventDefault();
      let value = event.target.value; 
      setARSUser(value); 
      let count = renderCount; 
      count++; 
      setRenderCount(count); 
    }

     useEffect(() => {
      //get permission level for protected subpages.
      const getARS = async () => {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "/api/accountable/adminusersfilter",
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        if (responseData) {setFilterByARS(responseData)};
      };
      if (auth.accountableAdmin) getARS();
    }, [auth.accountableAdmin]);

    return {notifications, reRender, auth, isLoading, error, clearError, filterByARS, setFilterByARS, handleSetArsUser, arsUser, setARSUser}; 
}
