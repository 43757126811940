"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.createConfirmationCreater = void 0;

var _domTree = require("./mounter/domTree");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var createConfirmationCreater = function createConfirmationCreater(mounter) {
  return function (Component) {
    var unmountDelay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
    var mountingNode = arguments.length > 2 ? arguments[2] : undefined;
    return function (props) {
      var mountId;
      var promise = new Promise(function (resolve, reject) {
        try {
          mountId = mounter.mount(Component, _objectSpread({
            reject: reject,
            resolve: resolve,
            dispose: dispose
          }, props), mountingNode);
        } catch (e) {
          console.error(e);
          throw e;
        }
      });

      function dispose() {
        setTimeout(function () {
          mounter.unmount(mountId);
        }, unmountDelay);
      }

      return promise.then(function (result) {
        dispose();
        return result;
      }, function (result) {
        dispose();
        return Promise.reject(result);
      });
    };
  };
};

exports.createConfirmationCreater = createConfirmationCreater;

var _default = createConfirmationCreater((0, _domTree.createDomTreeMounter)());

exports["default"] = _default;