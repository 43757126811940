/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Card,
  CardContent,
  Grid,
  Button,
  TableBody,
  Typography
} from '@material-ui/core';
import { AuthContext } from '../context/auth-context';
import { useHttpClient } from '../hooks/http-hook';
import EmailIcon from '@material-ui/icons/Email';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
const CareTeamModal = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [users, setUsers] = useState(null);
  useEffect(() => {
    //get careteam for tenant
    const getUsers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + '/api/accountable/getcareteam',
          'POST',
          JSON.stringify({
            id: props.id
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        if (responseData) setUsers(responseData);
      } catch (err) {}
    };
    if (props.id) getUsers();
  }, [props.id, sendRequest]);

  const handleClose = () => {
    props.clear();
  };

  const deleteUser = async (event) => {
    event.preventDefault();
    let target = event.currentTarget.value; //current target gets lost on async.
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + '/api/users/removecaseload',
        'POST',
        JSON.stringify({
          userId: props.uid,
          providerId: target
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token
        }
      );
      if (responseData.valid) {
        setUsers(users.filter((user) => user._id !== target)); //remove deleted object
      }
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <DialogTitle>{props.name}'s Care Team</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Relationship</TableCell>
                    <TableCell>Share All Information</TableCell>
                    <TableCell>Active</TableCell>
                    {auth.accountableAdmin && <TableCell>Delete</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users &&
                    users.map((user) => {
                      let relationship = 'NA';
                      if (user.relationship) {
                        switch (user.relationship) {
                          case '0':
                            relationship = 'Friend/Family';
                            break;
                          case '1':
                            relationship = 'Therapist';
                            break;
                          case '2':
                            relationship = 'Psychiatrist';
                            break;
                          case '3':
                            relationship = 'IOP Counselor';
                            break;
                          case '4':
                            relationship = 'Alumni Coordinator';
                            break;
                          case '5':
                            relationship = 'Partner Program';
                            break;
                          case '6':
                            relationship = 'Recovery Coach';
                            break;
                          case '7':
                            relationship = 'Sober Living';
                            break;
                          case '8':
                            relationship = 'Case Manager';
                            break;
                          case '9':
                            relationship = 'Other';
                            break;
                          case '10':
                            relationship = 'Lawyer';
                            break;
                          case '11':
                            relationship = 'Probation Officer';
                            break;
                          case '12':
                            relationship = 'Employer';
                            break;
                        }
                      }
                      return (
                        <TableRow key={user._id}>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>{user.firstName}</TableCell>
                          <TableCell>{user.lastName}</TableCell>
                          <TableCell>{user.phoneNumber}</TableCell>
                          <TableCell>{relationship}</TableCell>
                          <TableCell>
                            {!user.toxOnly ? (
                              <CheckIcon alt="Active" color="secondary" />
                            ) : (
                              <HighlightOffIcon alt="Only Tox Results" color="error" />
                            )}
                          </TableCell>
                          <TableCell>
                            {user.confirmEmail ? (
                              <EmailIcon alt="Registration Email Sent" color="error" />
                            ) : (
                              <CheckIcon alt="Active" color="secondary" />
                            )}
                          </TableCell>
                          {auth.accountableAdmin && (
                            <TableCell>
                              <Button
                                variant="contained"
                                color="secondary"
                                value={user._id}
                                key={user._id}
                                onClick={deleteUser}>
                                <DeleteIcon></DeleteIcon>
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default CareTeamModal;
