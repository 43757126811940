import React, {useContext} from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AuthContext } from "../../../common/context/auth-context";
import { useHttpClient } from "../../../common/hooks/http-hook";

const ReplyRequired = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const handleClose = () => {
    props.close();
  };

  const updateResponse = async (event) => {
        event.preventDefault();
        let reply = event.currentTarget.value; 
        try {
          const responseData = await sendRequest(
            process.env.REACT_APP_BACKEND_URL + "/api/sms/replyrequired",
            "POST",
            JSON.stringify({
              mid: props.mid,
              replyrequired: reply
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {}
        props.close();
      };


  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Is a response required for this message?</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonGroup>
            <Button onClick={updateResponse} variant="contained" value={false} color="primary">
              No
            </Button>
            <Button onClick={updateResponse}  variant="contained"  value={true} color="secondary" autoFocus>
              Yes
            </Button>
            <Button onClick={updateResponse}  variant="contained"  value={3} color="secondary">
              Mark Unread
            </Button>
          </ButtonGroup>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ReplyRequired;
