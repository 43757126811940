import React, { useEffect, useState, useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useHttpClient } from '../hooks/http-hook'; // Import your HTTP hook if you have one.
import { AuthContext } from '../context/auth-context'; // Import your AuthContext if you have one.
import LoadingSpinner from './LoadingSpinner';
import ErrorModal from './ErrorModal';

const AddOrderModal = ({ open, onClose, clear }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient(); // Assuming you use a custom hook for HTTP requests
  const [orderDetails, setOrderDetails] = useState({
    kitsRequested: '',
    orderType: 'Lab Test',
    shippingAddressLine1: '',
    shippingAddressLine2: '',
    shippingCity: '',
    shippingState: '',
    shippingZip: '',
    quantity: '',
    quantity2: '',
    quantity3: '',
    carrier: '',
    trackingNumber: '',
    shippingStatus: 'Pending',
    theUser: '',
    expeditedShipping: '0', // Default to 'No'
    expeditedShippingCharge: '0', // Default to 'No'
    specialShippingRate: '',
    notes: ''
  });

  // Fetch client information when `open` changes and is truthy.
  useEffect(() => {
    const fetchClientInfo = async () => {
      if (!open) return; // Do nothing if `open` is falsy.
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/accountable/user`,
          'POST',
          JSON.stringify({ id: open }),
          { 'Content-Type': 'application/json', Authorization: `Bearer ${auth.token}` }
        );
        if (responseData && responseData.shippingAddressLine1) {
          setOrderDetails((prevState) => ({
            ...prevState,
            shippingAddressLine1: responseData.shippingAddressLine1,
            shippingAddressLine2: responseData.shippingAddressLine2,
            shippingCity: responseData.shippingCity,
            shippingState: responseData.shippingState,
            shippingZip: responseData.shippingZip,
            theUser: responseData._id
          }));
        }
      } catch (error) {
        console.error('Failed to fetch client information:', error);
      }
    };

    fetchClientInfo();
  }, [open, sendRequest, auth.token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrderDetails({ ...orderDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/order/submitOrder`,
        'POST',
        JSON.stringify(orderDetails),
        { 'Content-Type': 'application/json', Authorization: `Bearer ${auth.token}` }
      );
      setOrderDetails({
        kitsRequested: '',
        orderType: 'Lab Test',
        shippingAddressLine1: '',
        shippingAddressLine2: '',
        shippingCity: '',
        shippingState: '',
        shippingZip: '',
        quantity: '',
        quantity2: '',
        carrier: '',
        trackingNumber: '',
        shippingStatus: 'Pending',
        theUser: '',
        expeditedShipping: '0',
        expeditedShippingCharge: '0', // Default to 'No'
        specialShippingRate: '',
        notes: ''
      });
      clear();
    } catch (error) {
      console.error('Failed to fetch client information:', error);
    }
    console.log(orderDetails);
    // Submit logic here
  };

  return (
    <>
      {isLoading && <LoadingSpinner open={isLoading} />}
      {error && (
        <ErrorModal open={!!error} title="Error Ordering" alert={error} clearError={clearError} />
      )}
      <Dialog
        open={!!open} // Ensure dialog opens only if `open` is truthy
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth>
        <DialogTitle id="form-dialog-title">Add New Order</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12} xl={12} md={12} spacing={2}>
                <TextField
                  fullWidth
                  label="Order Type"
                  variant="outlined"
                  select
                  SelectProps={{ native: true }}
                  name="orderType"
                  value={orderDetails.orderType}
                  onChange={handleInputChange}>
                  <option value="Lab Test">Lab Test</option>
                  <option value="ShipBob Lab Test">ShipBob Lab Test</option>
                  <option value="Lab Test and BAC">ShipBob Lab Test & BAC</option>
                  <option value="Instant Test">Instant Test</option>
                  <option value="Instant and BAC">Instant and BAC</option>
                  <option value="Breathalyzer">Breathalyzer</option>
                </TextField>
              </Grid>

              {/* Other fields remain unchanged */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label={
                    orderDetails &&
                    (orderDetails.orderType === 'Instant and BAC' ||
                      orderDetails.orderType === 'Lab Test and BAC')
                      ? 'Test Quantity'
                      : 'Quantity'
                  }
                  fullWidth
                  variant="outlined"
                  name="kitsRequested"
                  value={orderDetails.kitsRequested}
                  onChange={handleInputChange}
                />
              </Grid>
              {orderDetails &&
                (orderDetails.orderType === 'Instant and BAC' ||
                  orderDetails.orderType === 'Lab Test and BAC') && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="BAC Quantity"
                      fullWidth
                      variant="outlined"
                      name="quantity2"
                      value={orderDetails.quantity2}
                      onChange={handleInputChange}
                    />
                  </Grid>
                )}
              {/* Address fields are now controlled inputs that might be auto-filled from API */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Shipping Address Line 1"
                  fullWidth
                  variant="outlined"
                  name="shippingAddressLine1"
                  value={orderDetails.shippingAddressLine1}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Shipping Address Line 2"
                  fullWidth
                  variant="outlined"
                  name="shippingAddressLine2"
                  value={orderDetails.shippingAddressLine2}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="City"
                  fullWidth
                  variant="outlined"
                  name="shippingCity"
                  value={orderDetails.shippingCity}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="State"
                  fullWidth
                  variant="outlined"
                  name="shippingState"
                  value={orderDetails.shippingState}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Zip Code"
                  fullWidth
                  variant="outlined"
                  name="shippingZip"
                  value={orderDetails.shippingZip}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <br />
                <TextField
                  label="Notes"
                  fullWidth
                  variant="outlined"
                  name="notes"
                  value={orderDetails.notes}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDetails.expeditedShipping === '1'} // Assuming '1' means Yes
                      onChange={(e) =>
                        handleInputChange({
                          target: { name: 'expeditedShipping', value: e.target.checked ? '1' : '0' }
                        })
                      }
                      name="expeditedShipping"
                      color="primary"
                    />
                  }
                  label="Expedited Shipping - Initial Order - No Charge"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={orderDetails.expeditedShippingCharge === '1'} // Assuming '1' means Yes
                      onChange={(e) =>
                        handleInputChange({
                          target: {
                            name: 'expeditedShippingCharge',
                            value: e.target.checked ? '1' : '0'
                          }
                        })
                      }
                      name="expeditedShippingCharge"
                      color="primary"
                    />
                  }
                  label="Expedited Shipping - Charge"
                />
                <Grid item xs={12} sm={4}>
                  <br />
                  <TextField
                    label="Special Shipping Rate"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    variant="outlined"
                    name="specialShippingRate"
                    value={orderDetails.specialShippingRate}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <DialogActions>
              <Button onClick={clear} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" onClick={handleSubmit}>
                Submit Order
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddOrderModal;
