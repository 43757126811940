import { createContext } from 'react';
import { AuthContextType } from '../../types/auth';

export const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  token: null,
  userId: null,
  email: null,
  tenant: null,
  error: null,
  authProvider: false,
  stripeStatus: null,
  tokenExpirationDate: null,
  accountableAdmin: false,
  siteAdmin: false,
  harambe: false,
  provider: false,
  programProvider: false,
  program: false,
  client: false,
  wsClient: null,
  loading: true,
  login: () => {},
  logout: () => {},
  clearError: () => {},
  updateSettings: () => {},
  setAccountableAdmin: () => {},
  setSiteAdmin: () => {},
  setHarambe: () => {},
  setProvider: () => {},
  setProgram: () => {},
  setProgramProvider: () => {},
  setClient: () => {},
  setWsClient: () => {}
});
