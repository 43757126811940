import React from 'react'; 
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'; 

const ConfirmationDialog = props => {

    const handleClose = () => {
        props.close(); 
    }
    return (
        <React.Fragment>
            <Dialog open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true} 
            maxWidth={props.size||"sm"}
            >
            <DialogTitle>{props.title}</DialogTitle>
             <DialogContent>
                 <DialogContentText>
                     {props.alert}
                 </DialogContentText>
             </DialogContent>
             <DialogActions>
                 {props.discharge && (props.dxFromProgram ? <Button onClick={props.runDischarge} onFocus fullWidth={props.fullWidth || false} variant={props.variant || "contained"} color="secondary" autoFocus>{props.buttonTitle ||"Re-Enroll Member"} </Button>:<Button onClick={props.runDischarge} onFocus fullWidth={props.fullWidth || false} variant={props.variant || "contained"} color="secondary" autoFocus>{props.buttonTitle ||"Discharge Member"} </Button>)}
                 <Button onClick={props.runConfirmation} onFocus fullWidth={props.fullWidth || false} variant={props.variant || "text"} color="secondary" autoFocus>{props.buttonTitle ||"Switch Payment Responsibility"} </Button>
                 <Button onClick={handleClose} fullWidth={props.fullWidth || false} variant={props.variant || "text"} color="primary" autoFocus>{"Cancel"} </Button>
             </DialogActions>
            </Dialog>
        </React.Fragment>
    );

}
export default ConfirmationDialog; 