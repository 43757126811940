import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { confirmable } from 'react-confirm';

const Confirmation = ({
  okLabel = 'Yes',
  cancelLabel = 'No',
  title = 'Confirmation',
  confirmation,
  show,
  proceed,
  dismiss,
  cancel,
  modal,
}) => {
  return (
      <Dialog
        modal={modal}
        open={show}
        onClose={dismiss}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          {confirmation}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={cancel}
          >
            {cancelLabel}
          </Button>
          <Button
            variant="contained"
            onClick={proceed}
          >
            {okLabel}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default confirmable(Confirmation);