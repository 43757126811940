import { colors } from '@mui/material';
import palette from '../palette';
import typography from '../typography';

const components = {
  // MuiButton: {
  //   styleOverrides: {
  //     contained: {
  //       boxShadow:
  //         '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
  //       backgroundColor: '#FFFFFF'
  //     }
  //   }
  // },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: palette.icon,
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.03)'
        }
      }
    }
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        ...typography.body1,
        borderBottom: `1px solid ${palette.divider}`
      }
    }
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: colors.grey[50]
      }
    }
  },
  // MuiTableRow: {
  //   styleOverrides: {
  //     root: {
  //       '&.Mui-selected': {
  //         backgroundColor: palette.background.default
  //       },
  //       '&:hover': {
  //         backgroundColor: palette.background.default
  //       }
  //     }
  //   }
  // },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: 8
      }
    }
  }
};

export default components;
